export const historyLists = [
    {
        id: 'current',
        titleKey: 'history.sections.current.title',
        items: [
            {
                name: 'Designare To.',
                nameKey: 'history.items.designare.name',
                url: 'https://snapprinting.org',
                period: '2023.07 - current',
                periodKey: 'history.period.current',
            }
        ]
    }
]; 