import React from "react";
import "./Pages.css"
import { useTranslation } from "react-i18next";
import { historyLists } from "../data/historyLists";

function History() {
    const { t } = useTranslation();

    return (
        <div className="sectionContainer">
            <div className="noBanner"></div>
            <section className="maxWidth titleCenter">
                <h1>{t('history.title')}</h1>
                <p>{t('history.description')}</p>
            </section>
            
            <div className="hrWrapper maxWidth_small"><hr/></div>

            {historyLists.map((section) => (
                <section key={section.id} className="maxWidth_small">
                    <h3>{t(section.titleKey)}</h3>
                    <div className="historyContainer">
                        {section.items.map((item, index) => (
                            <div key={index} className="historyItem">
                                <a href={item.url} target="_blank" rel="noopener noreferrer">
                                    <span>{item.nameKey ? t(item.nameKey) : item.name}</span>
                                </a>
                                <span>{item.periodKey ? t(item.periodKey) : item.period}</span>
                            </div>
                        ))}
                    </div>
                </section>
            ))}
        </div>
    );
}

export default History;