import React, { useState } from "react";
import "./ListItem.css";
import { useTranslation } from "react-i18next";
import VideoModal from "./VideoModal";

function ListItem({ item }) {
    const { t } = useTranslation();
    const [isModalOpen, setIsModalOpen] = useState(false);

    // 유튜브 비디오 ID 추출 함수
    const getYouTubeVideoId = (url) => {
        const match = url.match(/(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))([^&?]+)/);
        return match ? match[1] : null;
    };

    // 썸네일 클릭 핸들러
    const handleThumbnailClick = (e) => {
        e.preventDefault();
        setIsModalOpen(true);
    };

    return (
        <>
            <a href={item.url} target="_blank" rel="noopener noreferrer" className="listItem">
                {item.type === 'video' ? (
                    <div className="media-container">
                        <div className="media-wrapper" onClick={handleThumbnailClick}>
                            <img 
                                src={`https://i.ytimg.com/vi/${getYouTubeVideoId(item.url)}/maxresdefault.jpg`}
                                alt={t(`highlights.items.${item.id}.title`)}
                            />
                            <div className="play-button">
                                <i className="fa-solid fa-play"></i>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="media-container">
                        <div className="media-wrapper">
                            <img 
                                src={item.imageUrl} 
                                alt={t(`highlights.items.${item.id}.title`)} 
                                loading="lazy"
                            />
                        </div>
                    </div>
                )}
                <div className="listInfo">
                    <div className="listMeta">
                        <span className="subtitle">{t(`highlights.items.${item.id}.subtitle`)}</span>
                        <span className="date">{item.date}</span>
                    </div>
                    <h3>{t(`highlights.items.${item.id}.title`)}</h3>
                </div>
            </a>
            {isModalOpen && item.type === 'video' && (
                <VideoModal
                    videoUrl={item.embedUrl}
                    title={t(`highlights.items.${item.id}.title`)}
                    onClose={() => setIsModalOpen(false)}
                />
            )}
        </>
    );
}

export default ListItem; 