import React, { useState, useEffect } from 'react';
import "./Header.css"
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useScrollAnimation } from '../hooks/useScrollAnimation';

function Header() {
    const [scrolled, setScrolled] = useState(false);
    const { t, i18n } = useTranslation();
    const [currentLang, setCurrentLang] = useState(i18n.language);
    const { scrollTo } = useScrollAnimation();
    const location = useLocation();

    const toggleLanguage = () => {
        const newLang = currentLang === 'ko' ? 'en' : 'ko';
        i18n.changeLanguage(newLang);
        setCurrentLang(newLang);
    };

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 10) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const handleNavClick = (path) => {
        if (location.pathname === path) {
            scrollTo(0);
        }
    };

    return (
        <header className={scrolled ? 'scrolled' : ''}>
            <a href="/" id="textL">sihyunlights</a>
            <div id="headerMenu">
                <button onClick={toggleLanguage} className="langToggle">
                    {currentLang === 'ko' ? 'KO' : 'EN'}
                </button>
                <div id="headerLinks">
                    <ul>
                        <li>
                            <a
                                href="https://behance.net/sihyunlights"
                                target="_blank" rel="noopener noreferrer"
                                aria-label="Behance"
                            ><i className="fa-brands fa-behance fa-lg"></i></a>
                        </li>
                        <li>
                            <a
                                href="https://instagram.com/sihyunlights"
                                target="_blank" rel="noopener noreferrer"
                                aria-label="Instagram"
                            ><i className="fa-brands fa-instagram fa-lg"></i></a>
                        </li>
                        <li>
                            <a
                                href="https://youtube.com/@sihyunlights"
                                target="_blank" rel="noopener noreferrer"
                                aria-label="YouTube"
                            ><i className="fa-brands fa-youtube fa-lg"></i></a>
                        </li>
                        <li>
                            <a
                                href="https://github.com/sihyunlts"
                                target="_blank" rel="noopener noreferrer"
                                aria-label="GitHub"
                            ><i className="fa-brands fa-github fa-lg"></i></a>
                        </li>
                    </ul>
                </div>
                <nav>
                    <ul>
                        <li>
                            <Link
                                to='/'
                                className={`nav-link ${location.pathname === '/' ? 'active' : ''}`}
                                onClick={() => handleNavClick('/')}
                            >{t('nav.home')}</Link>
                        </li>
                        <li>
                            <Link
                                to='/projects'
                                className={`nav-link ${location.pathname === '/projects' ? 'active' : ''}`}
                                onClick={() => handleNavClick('/projects')}
                            >{t('nav.projects')}</Link>
                        </li>
                        <li>
                            <Link
                                to='/history'
                                className={`nav-link ${location.pathname === '/history' ? 'active' : ''}`}
                                onClick={() => handleNavClick('/history')}
                            >{t('nav.history')}</Link>
                        </li>
                        <li>
                            <a className='nav-link' target="_blank" rel="noopener noreferrer" href="https://blog.sihyunlights.com/">{t('nav.blog')}</a>
                        </li>
                    </ul>
                </nav>
            </div>
            <a href="https://youtu.be/StAy4_K6NNI" id="textR">website</a>
        </header>
    );
}

export default Header;