import React from "react";
import "./Pages.css";
import List from "../components/List";
import { useTranslation } from "react-i18next";
import { mainHighlights } from "../data/lists";

function Main() {
    const { t } = useTranslation();

    return (
        <div className="sectionContainer">
            <img className="banner" src="/Main.jpg" alt="banner"></img>
            
            <section className="maxWidth">
                <div id="headContainer">
                    <div id="symbol"><div><img src="/Symbol.svg" alt="sihyunlights symbol" /></div></div>
                    <div id="profileInfo">
                        <img src="/Profile.jpg" alt="sihyunlights profile" />
                        <p>{t('main.profile_name')}</p>
                    </div>
                    <h1>빛을 통해 즐거움을 전달합니다</h1>
                </div>
                <p>{t('main.description')}</p>
            </section>
            <div className="hrWrapper maxWidth"><hr/></div>

            <section className="maxWidth">
                <h2>{t('highlights.title')}</h2>
                <List variant="large" data={mainHighlights} />
            </section>
            
        </div>
    );
}

export default Main;