import React from "react";
import ListItem from "./ListItem";
import { mainHighlights } from "../data/lists";
import { useTranslation } from "react-i18next";
import "./List.css";

function List({ variant = "large", data = mainHighlights }) {
    const { t } = useTranslation();

    const processDate = (date) => {
        return date.replace('present', t('common.present'));
    };

    const getListClassName = () => {
        switch(variant) {
            case "large":
                return "List ListL";
            case "medium":
                return "List ListM";
            default:
                return "List ListS";
        }
    };

    return (
        <div className={getListClassName()}>
            {data.map((item, index) => (
                <ListItem 
                    key={index} 
                    item={{
                        ...item,
                        displayTitle: t(`highlights.items.${item.id}.title`),
                        subtitle: t(`highlights.items.${item.id}.subtitle`),
                        date: processDate(item.date)
                    }} 
                />
            ))}
        </div>
    );
}

export default List; 