import { useRef } from 'react';
import { animate } from 'framer-motion';

export const useScrollAnimation = () => {
    const isAnimating = useRef(false);
    const animation = useRef(null);

    const scrollTo = async (targetY) => {
        // 이전 애니메이션이 있다면 중단
        if (animation.current) {
            animation.current.stop();
        }

        isAnimating.current = true;
        
        animation.current = animate(window.scrollY, targetY, {
            duration: 0.8,
            ease: [0.15, 1, 0.3, 1],
            onUpdate: (value) => {
                if (isAnimating.current) {
                    window.scrollTo(0, value);
                }
            },
            onComplete: () => {
                isAnimating.current = false;
                animation.current = null;
            },
        });

        const handleUserScroll = () => {
            if (isAnimating.current) {
                isAnimating.current = false;
                if (animation.current) {
                    animation.current.stop();
                    animation.current = null;
                }
            }
        };

        // 스크롤 이벤트 리스너 추가
        window.addEventListener('wheel', handleUserScroll, { passive: true });
        window.addEventListener('touchmove', handleUserScroll, { passive: true });

        // 애니메이션이 완료되면 이벤트 리스너 제거
        animation.current.then(() => {
            window.removeEventListener('wheel', handleUserScroll);
            window.removeEventListener('touchmove', handleUserScroll);
        });
    };

    return { scrollTo };
}; 