// 공통으로 사용되는 항목들을 별도로 정의
const items = {
    stillKids: {
        id: 'stillKids',
        type: 'video',
        embedUrl: 'https://www.youtube.com/embed/WV9Ho5fx4Lk?color=white&rel=0&modestbranding=1&playsinline=1',
        url: 'https://youtu.be/WV9Ho5fx4Lk',
        title: 'YouTube video player',
        date: '2021.05'
    },
    peopleLogistics: {
        id: 'peopleLogistics',
        type: 'image',
        url: 'https://www.instagram.com/p/C2PcizmhwEC/',
        imageUrl: '/PeopleLogistics.jpg',
        date: '2024.01'
    },
    fluid: {
        id: 'fluid',
        type: 'image',
        url: 'https://x.com/siriusinyoursky',
        imageUrl: '/fluid.jpg',
        date: '2020.04 - present'
    },
    bt21: {
        id: 'bt21',
        type: 'video',
        embedUrl: 'https://www.youtube.com/embed/CCoEz_RywPI?color=white&rel=0&modestbranding=1&playsinline=1',
        url: 'https://youtu.be/CCoEz_RywPI',
        title: 'YouTube video player',
        date: '2020.07'
    },
    reformParty: {
        id: 'reformParty',
        type: 'image',
        url: 'https://www.instagram.com/p/C7JCNnTh7FX/',
        imageUrl: '/ReformParty.jpg',
        date: '2024.05'
    },
    cable: {
        id: 'cable',
        type: 'image',
        url: 'https://www.instagram.com/p/C4srOTqpVyh/',
        imageUrl: '/Cable.jpg',
        date: '2024.03'
    },
    peopleParty: {
        id: 'peopleParty',
        type: 'image',
        url: 'https://www.instagram.com/p/C0ZL2yoBYfz/',
        imageUrl: '/PeopleParty.jpg',
        date: '2023.12'
    },
    newParty: {
        id: 'newParty',
        type: 'image',
        url: 'https://www.behance.net/gallery/183282899/_',
        imageUrl: '/NewParty.jpg',
        date: '2023.08'
    },
    yashinoki: {
        id: 'yashinoki',
        type: 'video',
        embedUrl: 'https://www.youtube.com/embed/c0sW0KuZAok?color=white&rel=0&modestbranding=1&playsinline=1',
        url: 'https://youtu.be/c0sW0KuZAok',
        title: 'YouTube video player',
        date: '2023.08'
    }
};

// 각 섹션별 아이템 ID 리스트
const lists = {
    main: ['stillKids', 'peopleLogistics', 'fluid', 'bt21'],
    design: ['fluid', 'reformParty', 'cable', 'peopleLogistics', 'peopleParty', 'newParty'],
    visual: ['yashinoki', 'stillKids', 'bt21']
};

// 리스트 생성 헬퍼 함수
const createList = (ids) => ids.map(id => items[id]);

// 익스포트할 리스트들
export const mainHighlights = createList(lists.main);
export const projectDesign = createList(lists.design);
export const projectVisual = createList(lists.visual); 