import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useScrollAnimation } from '../hooks/useScrollAnimation';

function ScrollToTop() {
    const { pathname } = useLocation();
    const { scrollTo } = useScrollAnimation();

    useEffect(() => {
        scrollTo(0);
    }, [pathname, scrollTo]);

    return null;
}

export default ScrollToTop; 