import React from "react";
import List from "../components/List";
import { useTranslation } from "react-i18next";
import { projectDesign, projectVisual } from "../data/lists";
import "./Pages.css";

function Projects() {
    const { t } = useTranslation();

    return (
        <div className="sectionContainer">
            <div className="noBanner"></div>
            <section className="maxWidth titleCenter">
                <h1>{t('projects.title')}</h1>
                <p>{t('projects.description')}</p>
            </section>
            <div className="hrWrapper maxWidth"><hr/></div>

            <section className="maxWidth">
                <h2>{t('projects.design')}</h2>
                <List variant="medium" data={projectDesign} />
            </section>
            <div className="hrWrapper maxWidth"><hr/></div>

            <section className="maxWidth">
                <h2>{t('projects.visual')}</h2>
                <List variant="medium" data={projectVisual} />
            </section>
        </div>
    );
}

export default Projects;