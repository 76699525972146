import React from "react";
import "./Footer.css";
import { useTranslation } from "react-i18next";

function Footer() {
    const { t } = useTranslation();

    return (
        <footer>
            <p>© 2025 sihyunlights</p>
            <div id="footerMenu">
                <a href="mailto:mail@sihyunlights.com">{t('footer.send_mail')}</a>
                <div className="divider"></div>
                <a href="https://github.com/sihyunlts/sihyunlights-web" target="_blank" rel="noopener noreferrer">
                    {t('footer.help_website')}
                </a>
            </div>
        </footer>
    );
}

export default Footer;