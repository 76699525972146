import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import './VideoModal.css';

function VideoModal({ videoUrl, title, onClose }) {
    const [isClosing, setIsClosing] = useState(false);
    const [isEntering, setIsEntering] = useState(true);

    useEffect(() => {
        // 모달이 열릴 때 스크롤 방지
        document.body.style.overflow = 'hidden';
        
        // 진입 애니메이션 시작
        requestAnimationFrame(() => {
            setIsEntering(false);
        });

        // 컴포넌트가 언마운트될 때 스크롤 복원
        return () => {
            document.body.style.overflow = 'unset';
        };
    }, []);

    // 모달 닫기 핸들러
    const handleClose = () => {
        setIsClosing(true);
        setTimeout(() => {
            onClose();
        }, 300);
    };

    // 배경 클릭 시 모달 닫기
    const handleBackdropClick = (e) => {
        if (e.target === e.currentTarget) {
            handleClose();
        }
    };

    // 모달 컨텐츠
    const modalContent = (
        <div 
            className={`video-modal-backdrop ${isEntering ? 'entering' : ''} ${isClosing ? 'closing' : ''}`} 
            onClick={handleBackdropClick}
        >
            <div className="video-modal-content">
                <iframe
                    src={`${videoUrl}&autoplay=1&rel=0&modestbranding=1`}
                    title={title}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                />
            </div>
        </div>
    );

    // Portal을 사용하여 모달을 body 직접 자식으로 렌더링
    return createPortal(
        modalContent,
        document.body
    );
}

export default VideoModal; 